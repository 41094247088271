@import url("https://fonts.googleapis.com/css2?family=Amita:wght@700&family=Archivo:wght@400;500;600;700&family=Cabin&family=Inter:wght@400;500;600;900&family=Libre+Franklin&family=Montserrat:wght@400;500;700;800&family=Public+Sans:wght@500;600;700&family=Roboto&family=Rubik+Spray+Paint&family=Secular+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Plus+Jakarta+Sans:wght@300;400;500;600;700&family=Shadows+Into+Light&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Plus+Jakarta+Sans:wght@300;400;500;600;700&family=Shadows+Into+Light&display=swap");
.services-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
p,
h1,
h2,
h3,
h4,
h5 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans,
    sans-serif;
}

.services-section > div {
  text-align: center;
  padding: 20px;
  /* background-color: #f5f5f5; */
  /* border: 1px solid #ddd; */
}
.hwhw {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  color: white;
}
.hwhw p {
  display: flex;
  align-items: center;
}
.btnnnnn {
  width: fit-content;
}
.service-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  justify-content: center;
  align-items: center;
}
.workcont h2 {
  z-index: 9;
}
.headdddd {
  font-weight: 500;
  font-size: 40px;
  z-index: 9;
  line-height: 100%;
  font-family: Lexend, sans-serif;
}

.paraaaa {
  font-family: Mulish, sans-serif;
  font-weight: 400;
}

.service-card {
  text-align: start;
  padding: 20px;
  background-color: black;
  border-radius: 10px;
}
.service-card span {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.service-card span h3 {
  font-family: Lexend, sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: white;
}
.service-card span p {
  color: rgba(209, 207, 207, 0.7);
  line-height: 150%;
  font-size: 14px;
}
.service-card span h4 {
  font-family: Lexend, sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.icon-container {
  background-color: #162446;
  border-radius: 10px;
  padding: 10px;
  color: #fff;
  text-align: center;
  width: 60px;
  height: 60px;
}
.icon-container svg {
  font-size: 2rem;
}
.services-section {
  background: #12132a;
  /* box-shadow: 2px 2px 5px 10px black; */
  /* margin: 5rem 0; */
}
@media (max-width: 768px) {
  .services-section {
    grid-template-columns: 1fr;
  }

  .service-cards {
    grid-template-columns: 1fr;
  }
}

p {
  color: #b8b8c5;
  font-family: Inter;
}
.hswww {
  bottom: 1000%;
  right: 0;
}
h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
}
h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
}
li {
  font-family: "Inter";
  font-style: normal;
  font-weight: 5000;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

.work:nth-child(1) {
  transition-delay: 20ms;
}
.work:nth-child(2) {
  transition-delay: 600ms;
}
.work:nth-child(3) {
  transition-delay: 800ms;
}

.hidden,
.hiddenen {
  opacity: 0;
  transition: all 0.51s;
  filter: blur(5px);
  transform: translateX(100%);
}
.show {
  opacity: 1;
  transform: translateX(0%);
  filter: blur(0);
}
hr {
  height: 0.1px;
}
body {
  /* background: linear-gradient(100deg, #030c11, #203a43, #010e13); */
  font-family: Manrope, sans-serif;
  background-color: #12132a;
  overflow-x: hidden;
  /* animation: bgchange 2s linear 0s infinite alternate both; */
}
@keyframes bgchange {
  0% {
    background: linear-gradient(100deg, #030c11, #203a43, #010e13);
  }
  /* 33%{
    background: linear-gradient(10deg, #030c11,#010e13,  #203A43,);
  }
  66%{
    background: linear-gradient(200deg, #010e13,  #203A43,#030c11,);
  } */
  100% {
    background: linear-gradient(100deg, #030c11, #203a43, #010e13);
  }
}
h1,
h2 {
  font-weight: 800;
  text-align: center;
}
h2 {
  font-size: 35px;
}
.container {
  width: 90%;
  margin: 0 auto;
}
button {
  /* background: rgba(255, 0, 229, 0.9); */
  /* background: linear-gradient(150deg, rgb(50, 220, 236), rgb(175, 75, 200)); */
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.557);
  background: transparent;
  border: none;
  border-radius: 10px;
  color: #fff;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  padding: 1rem 24px;
  width: fit-content;
  height: fit-content;
  flex-shrink: 0;
  border: 2px solid #fff;
  transition: 0.2s ease-in-out;
}
button:hover {
  border: none;
  color: #12132a;
  background-color: #fff;
}
.herobu {
  display: flex;
  gap: 1rem;
  z-index: 9;
}

button a {
  color: white;
}

a {
  text-decoration: none;
  transition: 0.3s;
}
button:hover a,
a:active {
  color: #12132a;
}
ul {
  list-style: none;
}
header {
  /* background: linear-gradient(100deg, #141313, #202020, #0e0d0d); */
  /* background: linear-gradient(100deg, #030c11, #010e13, #203a43, #000000); */
  box-shadow: 0 2px 10px #615f5f;
  background: #12132a;
  z-index: 2;
  position: sticky;
  height: fit-content;
  width: 100vw;
  top: 0px;
  padding: 0.61rem 0;
  z-index: 2;
  left: 0px;
  right: 0px;
}
.headersect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /* overflow: hidden; */
}
.logo{
  display: flex;
  align-items: center;
  gap: 1rem;
}
.logo a {
  display: flex;
  align-items: center;
}
/* .logo a img{
  width: 2rem;
  height: 2rem;
} */
.logo a h1 {
  font-weight: 600;
  color: rgb(255, 255, 255);
  /* color: black; */
  font-size: 2rem;
  font-family: Inter;
}
.headersect nav ul {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.headersect nav ul li a {
  color: rgb(255, 255, 255);
  /* color: black; */
  font-weight: 400;
  font-size: 15px;
  transition: 0.5s;
  position: relative;
}
.headersect nav ul li button a {
  color: white;

  font-family: Inter;
}
.headersect nav ul li button:hover a {
  color: #010a0e;
}
.topright {
  position: absolute;
  right: 10%;
  top: 0;
  opacity: .3;
}
.headersect nav ul li a.active::before {
  text-decoration: underline;
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust the position of the underline as needed */
  width: 100%;
  height: 2px; /* Adjust the thickness of the underline as needed */
  background-color: white;
  /* background-color: #f28821; */
}

.herosect {
  position: relative;
  z-index: 1;

  /* height: 100vh; */
  /* background-image:linear-gradient(rgba(0, 0, 0, 0.672),rgba(0, 0, 0, 0.722)), url(./assets/bgc.jpg); */
  /* background:linear-gradient(rgb(15, 0, 43), rgb(8, 0, 26)); */
  background-size: contain;
  background-color: #fff;
  background: #12132a;
  /* background:url(./assets/background.png); */
  background-repeat: no-repeat;
  width: 100vw;
  /* margin-top: 3rem; */
  display: flex;
  justify-content: center;
  padding: 3rem 0;
  align-items: center;
}
.hersectcontainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  text-align: center;
  color: white;
  /* padding: 50rem 0; */
  gap: 2.2rem;
}
.hersectcontainer h1 {
  font-family: Plus Jakarta Sans;
  font-size: 2.53rem;
  font-style: normal;
  font-weight: 400;
  text-align: start;
  color: white;
  font-weight: 800;
}
.hersectcontainer p {
  /* color: #767799; */

  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 168.5%;
}
.hersectcontainer-text {
  display: flex;
  z-index: 9;
  flex-direction: column;
  gap: 0.51rem;
  text-align: start;
  position: relative;
}
html {
  overflow-x: hidden;
}
.hs {
  width: 31.5rem;
  height: 21.5rem;
  flex-shrink: 0;
  border-radius: 31.5rem;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #0f2b63 0%,
    rgba(24, 69, 163, 0) 100%
  );
  filter: blur(100px);
  position: absolute;
  top: -30%;
}
.hersectcontainer-text button {
  width: fit-content;
}
.partners {
  background: #EFF2F5;
  padding: 1.5rem 0;
  gap: 1rem;
  margin: 2rem 0;
}
.lifePartners {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: white;
  gap: 1rem;
}
.lifePartners .partners-img img {
  margin: 0 1rem;
  width: 12%;
}
.goalsect {
  /* height: 100vh; */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 0;
}
.hero- {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
}
.hero- span{
  width: 100%;
  display: flex;
  align-items: center;
  gap:1.5rem;
  margin-top: 1rem;
  z-index: 2
}
/* .topright {
  position: absolute;
  top: 10%;
  width: 6rem;

  right: 10%;
} */
.bottomleft {
  width: 6rem;
  position: absolute;
  bottom: 20%;
  opacity: .3;

  right: 0%;
}
.heroimage {
  width: 30rem;
}
.bottomlefty {
  position: absolute;
  top: 20%;
  left: 0%;
  opacity: .2;
}
.goalsect {
  background-color: #12132a;
}
.goalconta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  /* background-color: red; */
}
.visions {
  display: flex;
  margin-top: 2rem;
}
.visions p {
  padding: 10px;
  border-left: 5px solid #203d85;
  /* color: #767799; */
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 168.5%;
}
.visions img {
  width: 25rem;
  height: 25rem;
}
.objectives {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}
.objectives button {
  width: fit-content;
}

.worksect {
  color: white;
  background-color: #12132a;
}
.workcont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 0.5rem;
}
.workcont p {
  margin-bottom: 2rem;
}
.works {
  display: flex;
  position: relative;
  gap: 1.5rem;
  flex-wrap: wrap;
}
.work {
  border: 1px solid #61274d;
  border-radius: 24px;
  height: 300px;
  width: 300px;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s;
  text-align: center;
  transition: 0.2s ease;
}
.work:hover {
  background: #61274d;
}
.work a {
  color: white;
  gap: 0.71rem;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.footcontainer {
  color: white;
  display: flex;
  align-items: top;
  justify-content: space-between;
  gap: 0.5rem;
  /* background-color: gray; */
}

.footcontainer .socials {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* margin: 1rem 0; */
  /* background-color: red; */
}
.footcontainer h2 {
  font-weight: 500;
  color: rgb(228, 30, 221);
  font-size: 1.2rem;
  white-space: nowrap;
}
.footcontainer .socials span {
  display: flex;
  gap: 1rem;
}

.footcontainer .socials p {
  font-weight: 100;
  font-family: Mulish, sans-serif;
  font-size: 14px;
}
.iconsocial {
  transition: 0.2s ease;
}
.footcontainer .socials span .iconsocial:hover > * {
  color: #f28821;
}
.footcontainer .quickLinks,
.footcontainer .ourservices,
.footcontainer .contactus {
  /* background-color: green; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: fit-content;
  margin: 0 1rem;
}
.footcontainer .quickLinks ul,
.footcontainer .ourservices ul {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.footcontainer .quickLinks ul li,
.footcontainer .ourservices ul li {
  font-size: 0.91rem;
  color: #12132a;
}

@media screen and (max-width: 890px) {
  .footcontainer {
    flex-direction: column;
    align-items: start;
    /* justify-content: center; */
    /* background-color: red; */
    gap: 3rem;
    margin: 0;
  }
}
.footcontainer .contactus {
  align-items: start;
}
.footcontainer a {
  display: flex;
  align-items: center;
  color: #12132a;
}
.footcontainer a:hover {
  color: #f28821;
}

.contactMail {
  display: flex;
  gap: 0.2rem;
}
.copyright {
  width: 90%;
  margin: 0 auto;
  color: white;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}

.servicecont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.servicescont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  gap: 2rem;
  padding: 2rem 0;
  color: white;
}

.boxes {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 200px;
  flex-wrap: wrap;
  color: white;
  /* border: 1px solid rgba(255, 0, 229, 0.9); */
  width: 30rem;
  gap: 0.5rem;
  transition: 0.5s ease-in-out;
  position: relative;
}
.boxes::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  border-image: linear-gradient(150deg, rgb(50, 220, 236), rgb(175, 75, 200));
  border-image-slice: 1;
}
.boxes:hover {
  /* background-color: rgba(255, 0, 229, 0.9); */
  border: none;
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.533);
  transform: scale(1.071);
  /* color: black; */
}

.contactcont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding: 2rem;
  /* width: 50vw; */
  max-width: 600px;
  color: white;
}

.contactcont form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}
.contactcont form button {
  width: 100%;
  padding: 1rem 0;
}

.inputcontainer {
  padding: 15px;
  margin: 10px 0px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 10px;
  width: 100%;
}
.inputcontainer input,
.inputcontainer textarea {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: white;
}
.inputcontainer input:focus,
.inputcontainer textarea:focus {
  background: transparent;
}

.cryptoservicecontainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  color: white;
  flex-wrap: wra;
  gap: 3rem;
  padding: 2rem 0;
}

.cryptoservices {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.sesrvicesss {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  color: white;
  align-items: center;
  height: 200px;
  transition: 0.3s ease-in-out;

  position: relative;
}
.sesrvicesss::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  border-radius: 10px;
  border-image: linear-gradient(150deg, rgb(50, 220, 236), rgb(175, 75, 200));
  border-image-slice: 1;
}
.sesrvicesss:hover {
  transform: scale(1.07);
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.641);
}

.ham {
  display: none;
}
footer {
  padding: 5rem 0;
  background-color: white;
  position: relative;
}

/* ANIMATION */
.weigh {
  animation: tofro 10s ease-in-out 0s infinite alternate forwards running;
}

@keyframes tofro {
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }

  15% {
    transform: translateX(-30px) rotate(-9deg);
  }
  30% {
    transform: translateX(15px) rotate(9deg);
  }
  45% {
    transform: translateX(-15px) rotate(-6.6deg);
  }

  60% {
    transform: translateX(9px) rotate(5.4deg);
  }
  75% {
    transform: translateX(-6px) rotate(-4.2deg);
  }
}

.herohead {
  transform: scale(0.5);
  transition: transform 1s;
}

.heroparagraph {
  transform: translateY(200%);
  opacity: 0.5;
  transition: transform 1s ease-in-out;
}

#herobutton {
  transition: transform 1s ease-out;
  z-index: 2;
}

/* When the element is in the viewport */
.herohead.animate {
  transform: scale(1.1);
}

.heroparagraph.animate {
  transform: translateY(0);
  opacity: 1;
}

/* When the user scrolls to the height of the button */
#herobutton.animate {
  transform: translateY(0);
}

/* SERVICE ANIMATION */
.servieshead,
.contactconthead {
  transform: translateY(400%);
  opacity: 1.5;
  transition: 0.5s ease-in-out;
}
.servieshead.animate,
.contactconthead.animate {
  transform: translateY(0);
  opacity: 1;
}

.servicecont {
  transform: perspective(2500px) rotateY(-10deg);
  transition: 1s ease-in-out;
  position: relative;
}
.servicecont.animate {
  transform: perspective(0) rotateY(0);
}

#goalhead,
#goalparaph {
  transform: translateY(400%);
  transition: 0.5s ease-in-out;
}
#goalhead.animate,
#goalparaph.animate {
  transform: translateY(0);
}

.home-page .objectives {
  opacity: 0;
}

.home-page .objectives.slide-in {
  opacity: 1;
  animation: slide-in 0.5s ease-in-out forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(20%);
  }
  to {
    transform: translateY(0%);
  }
}

@media screen and (max-width: 890px) {
  .adbs {
    font-size: 0.81rem;
  }
  .objectives {
    width: 85%;
  }
  .ham {
    display: flex;
    cursor: pointer;
    z-index: 200;
  }
  .heroimage {
    width: 20rem;
  }
  body {
    overflow-x: hidden;
  }
  .cancel {
    transition: all 0.5s;
    z-index: 2;
  }
  .ham:hover {
    color: red;
  }
  .container {
    width: 80%;
  }
  .contactcont {
    width: 95%;
  }
  .logo {
    z-index: 3;
  }
  header {
    position: relative;
  }
  nav {
    position: absolute;
    background-color: #010a0e;
    border-radius: 10px;
    /* border: 2px solid #022a3c; */
    width: 100vw;
    height: 100vh;
    /* z-index: 2; */
    top: 120%;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    padding: 7rem 0;
    transition: 0.8s ease-in-out;
    transform: translateX(100%);
  }
  nav::before {
    content: "";
    position: inherit;
    /* z-index: inherit; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    border-image: linear-gradient(150deg, rgb(175, 75, 200), rgb(50, 220, 236));
    border-image-slice: 1;
  }
  .slideright {
    transform: translateX(0%);
  }
  nav ul {
    display: flex;
    flex-direction: column;
  }
  h1 {
    font-size: 30px;
  }
  h1,
  h2 {
    font-weight: 800;
    text-align: center;
  }
  .hersectcontainer {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
  }
  .topright {
    right: 20%;
  }
  .bottomleft {
    bottom: 0%;
    left: 65%;
    z-index: -1;
  }

  .hersectcontainer h1 {
    font-size: 40px;
  }
  .disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
  p {
    font-size: small;
    line-height: 1.5rem;
  }
  .lifePartners .partners-img img {
    width: 20%;
  }
  .herosect {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .visions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .works {
    align-items: center;
    justify-content: center;
  }
  footer {
    padding: 7rem 0;
  }
  .boxes {
    width: 23rem;
  }
  .cryptoservices {
    gap: 1.5rem;
  }
  .contactcont h1 {
    font-size: 30px;
    margin-bottom: 2rem;
  }
  .servicecont {
    grid-template-columns: 1fr;
  }
}

.loadingghgh {
  -webkit-animation: scaleOut 1s infinte ease-in-out;
  animation: scaleOut 1s infinte ease-in-out;
  /* background: linear-gradient(100deg, #030c11, #203A43, #010e13); */
  border-radius: 100%;
  border: 2px solid #011015;
  height: 12rem;
  width: 12rem;
  position: absolute;
}
.loadingghgh:nth-child(1) {
  border-bottom-width: 8px;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-color: rgb(255, 0, 221);
  animation: rotate1 2s linear infinite;
}
.loadingghgh:nth-child(2) {
  border-bottom-width: 0px;
  border-top-width: 0px;
  border-right-width: 8px;
  border-left-width: 0px;
  border-color: #00a2ff;
  animation: rotate2 2s linear infinite;
}
.loadingghgh:nth-child(3) {
  border-bottom-width: 0px;
  border-top-width: 8px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-color: rgb(0, 251, 13);
  animation: rotate3 2s linear infinite;
}
@keyframes rotate1 {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(-10deg) rotateZ(360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotateX(35deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(10deg) rotateZ(360deg);
  }
}
@keyframes rotate3 {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
.preeee {
  position: absolute;
  /* background-color: #011015; */
  z-index: 1;
}
.containerssss {
  align-items: center;
  /* background: linear-gradient(100deg, #030c11, #203a43, #010e13); */
  display: flex;
  height: 100vh;
  color: white;
  width: 100vw;
  justify-content: center;
  position: relative;
}
@keyframes scaleOut {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
    -webkit-transform: scale(1);
  }
}

.newsss {
  padding: 5rem 0;
}
.newsss ul {
  display: grid;
  /* flex-direction: column; */
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  padding-top: 5rem;
}
.newsss ul li {
  position: relative;
}
.newsss ul li h3 {
  /* position: absolute; */
  color: white;
  font-size: 1rem;
  bottom: 0;
}
.newsss ul img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.newsss h2,
.adbs {
  color: white;
}
.overrr {
  transition: 0.2s ease-in-out;
}
.overrr:hover {
  transform: scale(1.021);
}
.testisect {
  padding: 50px 0;
  background-color: #12132a;
}
.car-container {
  width: 100%;
  max-width: 800px;
  height: 220px;
  position: relative;
}

.carousel {
  border: 1px solid rgb(228, 30, 221);
  border-radius: 10px;
  width: 300px;
  position: absolute;
  color: white;
  opacity: 0.5;
  padding: 10px;
  margin: 0 10px;
  scroll-snap-align: center;
  transition: opacity 0.3s ease;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 1rem 0;
}

.carousel.active {
  opacity: 1;
}

.carousel p {
  margin-bottom: 10px;
}

.author {
  display: flex;
  align-items: center;
}

.author .img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
}

.testitracker {
  border: 2px solid red;
}

.tracar-container {
  display: flex;
  margin-top: 1rem;
  gap: 0.51rem;
}

.carousell {
  background-color: rgba(88, 83, 238, 0.538);
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.carousell.active {
  background-color: rgb(83, 109, 238);
}

.testi-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnsysy {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}
.answer.hide {
  display: none;
}
.answer.active {
  display: block;
}

/* FAQ.css */
.faqhead {
  color: white;
}
.service-card span H4 A {
  color: #c23893;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.faq-containerrr {
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
  /* background-color: #fff; */
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* align-items: center; */
  /* justify-content: center; */
}
.faq-item {
  border-bottom: 1px solid #ddd;
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  color: #fff;
  /* align-items: center; */
}
.answer {
  color: #e8e8e8;
  font-size: smaller;
}
.faww {
  display: flex;
  justify-content: space-between;
}
.faq-question {
  flex: 1;
}

.answer {
  display: none;
}

.active {
  display: block;
}

/* Media query for responsiveness */
@media (max-width: 768px) {
  .container {
    max-width: 100%; /* Adjust for full-width on smaller screens */
    /* padding: 10px; */
  }
  .logo a h1{
    font-size: 1.3rem;
  }
}
.footcontainer .socials .logo .footheader {
  color: black;
}
